import { publicRoutes, asyncRoutes } from '@/router/index'
import { getUserResources } from '@/api/role'
import { restoreTree, handleTree } from '@/utils'

const state = {
  // routes: [],
  addRoutes: [],
  userResources: [],
  menuType: 0,
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    // state.routes = publicRoutes.concat(routes)
  },
  SET_RESOURCES: (state, resources) => {
    state.userResources = resources
  },
  SET_MENU_TYPE(state, payload) {
    state.menuType = payload
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      getUserResources().then(res => {
        let allAsyncRoutes = handleTree(asyncRoutes);
        let routes = allAsyncRoutes.filter(it => {
          return res.data.includes(it.name) || it.meta?.isPublic
        })
        let result = restoreTree(routes)
        commit('SET_ROUTES', result)
        commit('SET_RESOURCES', res.data)
        resolve(result)
      })
    })
  }
}
const getters = {
  getRoutes(state) {
    // 添加业务类型筛选器
    let tree = filterTree(JSON.parse(JSON.stringify(state.addRoutes)), node => {
      if (node.isHide) {
        return false
      } else {
        if (state.menuType == 0){
          return true
        } else {
          if (node.menuType){
            return node.menuType == state.menuType
          } else {
            return true
          }
        }
      }
    })
    return tree
  },
  getMenuType: state => state.menuType,
}

function filterTree(tree, condition) {
  // 创建一个新的空树
  const filteredTree = [];

  // 遍历原始树的每个节点
  for (const node of tree) {
    // 检查节点是否满足条件
    if (condition(node)) {
      // 如果满足条件，则将节点添加到新树中
      filteredTree.push(node);

      // 如果节点有子节点，则递归过滤子节点
      if (node.children) {
        node.children = filterTree(node.children, condition);
      }
    }
  }

  return filteredTree;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
