<template>
  <el-menu
    :router="true"
    :default-active="activeMenu"
    class="el-menu-vertical-demo"
    :unique-opened="true"
  >
    <sidebar-item v-for="(route) in routesList" :key="route.path" :item="route" :base-path="route.path"></sidebar-item>
  </el-menu>
</template>

<script>
import SubmenuItem from "./components/item.vue";
import SidebarItem from './components/SidebarItem'
import Layout from '@/layout'
export default {
  components: { SubmenuItem , Layout, SidebarItem },
  data() {
    return {};
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    routesList() {
      let routes = this.$store.getters["permission/getRoutes"];
      return routes;
    },
  },
};
</script>

<style>
.el-menu{
  border: 0;
}
.el-menu-vertical-demo {
  width: 140px;
}
.el-menu-item,
.el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
}
.el-submenu .el-menu-item {
  min-width: 100px !important;
  height: 40px !important;
  line-height: 40px !important;
}
</style>
