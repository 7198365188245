<template>
  <div class="top-bar">
    <div class="container">
      <div class="logo">
        <img @click="goHome()" src="./images/logo.png" alt="" />
        <!-- <span class="title">企业后台</span> -->
         <div class="menu" @click="changeMenu">
          <div :class="{'active': menuType==0}" :data-menu="0">全部业务</div>
          <div :class="{'active': menuType==1}" :data-menu="1">采购业务</div>
          <div :class="{'active': menuType==2}" :data-menu="2">销售业务</div>
         </div>
      </div>
      <div class="user-info">
        <!-- <el-avatar :src="userInfo.avatar||circleUrl"></el-avatar> -->
        <el-dropdown @command="handleCommand">
          <el-avatar :src="userInfo.avatar || circleUrl"></el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item  command="AccountInfo">账号信息</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>{{ userInfo.main ? "管理员" : userInfo.roleName }}</span>
        <span>{{ userInfo.nickName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/store/mutation-types"
export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    ExternalLink() {
      return this.$store.state.app.ExternalLink
    },
    windowName() {
      return this.$store.state.app.windowName
    },
    menuType() {
      return this.$store.getters["permission/getMenuType"]
    }
  },
  methods:{
     handleCommand(name) {
        this.$router.push({name})
      },
      goHome() {
        location.href = this.ExternalLink + `?token=${getToken()}`
      },
      changeMenu(e){
        // 事件委托
        if(e.target.tagName === 'DIV'&&e.target.dataset.menu!==undefined){
          // 获取div标签自定义data-属性值
          this.$store.commit('permission/SET_MENU_TYPE', e.target.dataset.menu)
        }
      }
  }
};
</script>

<style scoped lang="scss">
.top-bar {
  background: #fff;
  .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    > div {
      padding: 8px 0;
      display: flex;
      align-items: center;
    }
    .logo {
      .title {
        margin-left: 50px;
        font-size: 20px;
        color: #666;
      }
      .menu{
        margin-left: 50px;
        display: flex;
        font-size: 16px;
        color: #333;
        >div{
          cursor: pointer;
          &:not(:last-child){
            margin-right: 50px;
          }
          &.active{
            color: #E60012;
            font-weight: bold;
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              background-color: #E60012;
            }
          }
        }
      }
    }
    .user-info {
      font-size: 14px;
      color: #333;
      span {
        margin-left: 13px;
      }
    }
  }
}
</style>
