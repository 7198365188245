<template>
  <div>
    <el-menu-item v-if="!item.children||item.children.length === 0" :index="basePath">
      {{ item.meta.title }}
    </el-menu-item>
    <el-menu-item v-else-if="item.children.length === 1" :index="resolvePath(item.children[0].path)">
      {{ item.children[0].meta.title }}
    </el-menu-item>
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)">
      <template slot="title">{{ item.meta.title }}</template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :base-path="resolvePath(child.path)"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from "path";
export default {
  name: "SidebarItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  methods: {
    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>

<style></style>
